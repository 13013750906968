import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import ConformityCards from 'components/Outdoor_Cameras/IN-9010_HD/Safety_Warnings/ConformityCards';
import NavButtons from 'components/Outdoor_Cameras/IN-9010_HD/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "IN-9010 Full HD Manual Safety Warnings",
  "path": "/Outdoor_Cameras/IN-9010_HD/Safety_Warnings/",
  "dateChanged": "2017-12-12",
  "author": "Mike Polinowski",
  "excerpt": "The IN-9010 HD is the first INSTAR camera with the new 1080p chipset.",
  "image": "./P_SearchThumb_IN-9010HD_Warranty.png",
  "social": "/images/Search/P_SearchThumb_IN-9010HD_Warranty.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-IN-9010HD_white.webp",
  "chapter": "Outdoor Cameras"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEOHelmet title='IN-9010 Full HD Manual Safety Warnings' dateChanged='2017-12-12' author='Mike Polinowski' tag='INSTAR IP Camera' description='The IN-9010 HD is the first INSTAR camera with the new 1080p chipset.' image='/images/Search/P_SearchThumb_IN-9010HD_Warranty.png' twitter='/images/Search/P_SearchThumb_IN-9010HD_Warranty.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Aussenkameras/IN-9010_HD/Sicherheit_Hinweise/' locationFR='/fr/Outdoor_Cameras/IN-9010_HD/Safety_Warnings/' crumbLabel="Safety" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h2 {...{
      "id": "in-9010-full-hd-safety-warnings",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#in-9010-full-hd-safety-warnings",
        "aria-label": "in 9010 full hd safety warnings permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`IN-9010 Full HD Safety Warnings`}</h2>
    <h3 {...{
      "id": "disclaimer",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#disclaimer",
        "aria-label": "disclaimer permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Disclaimer`}</h3>
    <p>{`All technical details and descriptions in this manual have been written with the greatest care. However, INSTAR Deutschland GmbH cannot entirely exclude mistakes in this manual. Therefore, we do not assume any legal responsibility or liability, which is result of wrong information in this manual. Descriptions, technical images and technical data are subject to change according to technical progress without notice. In addition, INSTAR Deutschland GmbH reserves the right to change this product and its manual without prior notice. We do not assume any guarantee with regard to the content of this document.`}</p>
    <h3 {...{
      "id": "electric-shock-warning",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#electric-shock-warning",
        "aria-label": "electric shock warning permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Electric Shock Warning`}</h3>
    <EuiSpacer mdxType="EuiSpacer" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "424px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/e26eb88d71740179dea1469cc8ef86f1/1cfa9/ElectricShock_Caution.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "62.60869565217391%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAAsTAAALEwEAmpwYAAACvklEQVQozzWQ20/TYBjGe+Uhxn9EE0z8izRG4wXxcE3UW9wYA5FBguO0xHEqrkUYIWxz28W6dm3Xr/2+nr/RdmETxEzHdrEDnxnE3+UvefM870NZliXLssALumFkM9nF2MJyfDmxnlhbXd1IbiS/JhPrieV4fDG2kMlkDMMQhIooSbqu7+zQFCGk2WyWSxwhZHtz6/6du2MPHj578vTV+PjL5y/evn7z4d37x2OP7t26vZlMEkKqslyvB4QQCOHouJgvri6vtNttVVWj09HE2np6/yB9kN7Z3mYZJn2Q/rK0NDf7CUHU7XZX4vFioTAcDmW5SnUuOxhjsVJpNprNZrPb7RJCAj+o4Vqj0fBOakEQDIfDfr/Xav1pNBqWaWLX/X1xASGiBEH4kcsdHR0Vi8Uyx0mSpKpqPp/PHB8XCoVcLpc5PlYBkGRJEIRCPs9xHFcqFfOFbDZLHR4e0jTNsuzW1tbu7m4qlaJpmmEYhmUZltnb22NYlqbp1Ldvqf/s0vT3/f1YLDb6GWoQarCGMYTQMs1Wq+V7HlAAgtC2LMd2zs7OoKbdSNdxMMady0vHcah+v69pGlCAbVlljnNd17Ft9RrP85SqYpmmCoB/4lVlWaxUlGoVQji8Guq6Pkp2XNfQdexiy7JcjG8CPc8LggC7LkLIvB4JXQM1aOh6r9czTZMaDAZiRRQFQakqQFEkUawIgmPbOkIqAFDTuFJJBYDneRWAMsdVZblcLrf/tm3bpnq9nsDzUNNqGEuiBIAqS5Lv+5IoaqoKNYgg5HleRzpCSFEUx3FqGHc6HYTQqHYN43pQb5yeuqNtfjqWjV03CIJf5+feiWfoRj0ILNOsYTwYDAghV1dXhBDf96n5+fnJyY+hUHhqKjIVngqHw6FQKDI9fWOi0Wh4pMKRSGTkI5HZ2dmZmZm5uc8TExP/ANGQYDj8wj4wAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e26eb88d71740179dea1469cc8ef86f1/e4706/ElectricShock_Caution.avif 230w", "/en/static/e26eb88d71740179dea1469cc8ef86f1/8b5ae/ElectricShock_Caution.avif 424w"],
              "sizes": "(max-width: 424px) 100vw, 424px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/e26eb88d71740179dea1469cc8ef86f1/a0b58/ElectricShock_Caution.webp 230w", "/en/static/e26eb88d71740179dea1469cc8ef86f1/96b95/ElectricShock_Caution.webp 424w"],
              "sizes": "(max-width: 424px) 100vw, 424px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e26eb88d71740179dea1469cc8ef86f1/81c8e/ElectricShock_Caution.png 230w", "/en/static/e26eb88d71740179dea1469cc8ef86f1/1cfa9/ElectricShock_Caution.png 424w"],
              "sizes": "(max-width: 424px) 100vw, 424px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/e26eb88d71740179dea1469cc8ef86f1/1cfa9/ElectricShock_Caution.png",
              "alt": "Electric Shock Warning",
              "title": "Electric Shock Warning",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`The warranty claim will expire in case of damages resulting from the non-observance of this manual. We do not assume any liability for consequential damages. We do not assume any liability for damages to persons and/or material whatsoever, which result from improper handling or non compliance with the safety instructions. The warranty claim will expire in such cases!`}</p>
    <h3 {...{
      "id": "please-read-the-following-safety-warnings-carefully",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#please-read-the-following-safety-warnings-carefully",
        "aria-label": "please read the following safety warnings carefully permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Please read the following safety warnings carefully:`}</h3>
    <ul>
      <li parentName="ul">{`Make sure the power cord is not near any hot surfaces.`}</li>
      <li parentName="ul">{`Place the Power and Network cable properly so that no one can be hurt.`}</li>
      <li parentName="ul">{`This device shall not be used by people (including children) with limited physical, sensory and mental capabilities. Only the trained person that knows how to use the device carefully can use the device.`}</li>
      <li parentName="ul">{`Children should be supervised to make sure they don’t play with the device.`}</li>
      <li parentName="ul">{`If the power cord of this device gets damaged, it can only be replaced by the manufacturer or the customer service person or a similar qualified person to avoid any damage to you and the device.`}</li>
      <li parentName="ul">{`Never do any repair by yourself. By opening the device, the warranty will automatically expire. If any repair is needed, please contact your INSTAR Service Centre.`}</li>
      <li parentName="ul">{`When cleaning, please never place the device in water.`}</li>
      <li parentName="ul">{`Make sure you only install the device in a 100V - 240V power socket.`}</li>
      <li parentName="ul">{`This device is only suitable for indoor use.`}</li>
      <li parentName="ul">{`Only use this device to secure your home, office and similar places. If you are planning to install the device in a public area please make sure you have all certificates to do so.`}</li>
      <li parentName="ul">{`Never install the device near explosive or flammable substances.`}</li>
      <li parentName="ul">{`Don’t use the camera for any other purpose that it isn’t made for.`}</li>
      <li parentName="ul">{`INSTAR does not give any warranty if you use any third party firmware or WebUI.`}</li>
    </ul>
    <h3 {...{
      "id": "declaration-of-conformity",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#declaration-of-conformity",
        "aria-label": "declaration of conformity permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Declaration of Conformity`}</h3>
    <EuiSpacer mdxType="EuiSpacer" />
    <ConformityCards mdxType="ConformityCards" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      